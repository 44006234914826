import React from "react"
import Slider from "react-slick"
import {StaticImage} from "gatsby-plugin-image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1
};

const ImageSlider = () => {
    return (
        <div style={{marginBottom: "-7px"}}>
            <Slider {...settings}>
                <div>
                    <StaticImage src="../images/bg/1clean-energy.jpeg" alt="Clean Energy" layout="fullWidth"/>
                </div>
                <div>
                    <StaticImage src="../images/bg/2member-owned.jpeg" alt="Member Owned" layout="fullWidth"/>
                </div>
                <div>
                    <StaticImage src="../images/bg/3your-community.jpeg" alt="Your Community" layout="fullWidth"/>
                </div>
            </Slider>
        </div>
    )
}

export default ImageSlider
