import React from "react"
import * as styles from "./homebuttons.module.css"
import {Link} from "gatsby";

const HomeButtons = () => {
    return (
        <div className={styles.homeButtons}>
            <div className={styles.homeButtonsTop}/>
            <div className={`${styles.homeButtonsCenter} homeButtons`}>
                <div className={styles.homeButtonsLeft}/>
                <div className={styles.buttons}>
                    <div>
                        <a rel="noopener noreferrer"
                           target="_blank"
                           href="https://outage.seiec.com/"
                           className={styles.outageButton} title="Outage Map">
                            OUTAGE MAP
                        </a>
                    </div>
                    <div>
                        <Link to={'/introduction-to-smarthub'} className={styles.billButton}>
                            Online<br/> Bill Payment
                        </Link>
                    </div>
                    <div>
                        <Link to={'/memberSurvey'} className={styles.surveyButton}>
                            Member Survey
                        </Link>
                    </div>
                    <div>
                        <Link to={'/introduction-to-smarthub'} className={styles.onlineBillButton}>
                            REGISTER for Online Bill Pay
                        </Link>
                    </div>
                    <div>
                        <Link to={'/apply-for-service'} className={styles.serviceButton}>
                            APPLY for Service
                        </Link>
                    </div>

                </div>
                <div className={styles.homeButtonsRight}/>
            </div>
            <div className={styles.homeButtonsBottom}/>
        </div>
    )
}

export default HomeButtons
