import * as React from "react"
import Layout from "../components/layout";
import Seo from "../components/seo";
import {graphql, Link} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";
import HomeButtons from "../components/homebuttons";
import ImageSlider from "../components/imageSlider";

const IndexPage = ({data}) => {
    const {
        wpCategory: {posts}
    } = data

    return (
        <Layout feature={<ImageSlider/>}>
            <Seo title="Home"
                 description="At SouthEastern Illinois Electric Cooperative, not only is the delivery of energy provided by your local cooperative, the generation and transmission of your energy is also provided by a local cooperative."/>
            <div className="container pr-0">
                <div className="flex flex-wrap">
                    <div className="w-full md:w-3/12">
                        <h3>Latest News</h3>
                        <ul className="py-3">
                            {posts.nodes.slice(0, 3).map(item =>
                                <li key={item.id} className="item-separator py-3">
                                    <Link to={item.slug} className="text-lg">{item.title}</Link>
                                    <div dangerouslySetInnerHTML={{__html: item.excerpt}}/>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="w-full md:w-6/12">
                        <HomeButtons/>
                        <div className="facebookContainer pb-4">
                            <h3>Find us on Facebook</h3>
                            <div className="pt-4">
                                <iframe
                                    src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FSouthEasternIllinoisElectric&tabs=timeline&width=410&height=400&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                                    width="410" height="400" scrolling="no"
                                    frameBorder="0" title="facebook"
                                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"/>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-3/12 space-y-2">
                        <div>
                            <a href="https://action.coop/" target="_blank" rel="noopener noreferrer">
                                <StaticImage
                                    src="../images/CAN_Logo.gif"
                                    alt="Seiec logo"
                                    placeholder="blurred"
                                    layout="constrained"
                                    backgroundColor="#ffffff"
                                />
                            </a>
                        </div>
                        <div>
                            <Link to={'/julie-information'}>
                                <StaticImage
                                    src="../images/julie.JPG"
                                    alt="Seiec logo"
                                    placeholder="blurred"
                                    layout="constrained"
                                    backgroundColor="#ffffff"
                                />
                            </Link>
                        </div>
                        <div>
                            <Link to={'/safety-tips'} className="green-header">
                                Safety Tips
                            </Link>
                        </div>
                        <div>
                            <Link to={'/marathon-water-heaters'}>
                                <StaticImage
                                    src="../images/marathon.png"
                                    alt="Seiec logo"
                                    placeholder="blurred"
                                    layout="constrained"
                                    backgroundColor="#ffffff"
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
    {
        wpCategory(id: { eq: "dGVybTo1MA==" }) {
          id
          name
          posts {
            nodes {
              id
              title
              slug
              excerpt
            }
          }
        }
    }
`

export default IndexPage
